<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Login v2 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <img :src="logo" alt="Tolki Logo" class="img-fluid" width="80%">
                </b-link>

                <b-card-title class="mb-1">Bienvenido(a) a <b>TOLKI SOFTWARE</b> 👋</b-card-title>
                <b-card-text class="mb-2">¡Inicie sesión con su cuenta y comience hacer uso de los diferentes módulos que ofrece la plataforma!</b-card-text>

                <!-- Form -->
                <validation-observer ref="loginForm">

                    <b-form
                        class="auth-login-form mt-2"
                    >

                        <!-- email -->
                        <b-form-group
                            label-for="email"
                            label="Correo electrónico">
                            <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email">
                                <b-form-input
                                    id="email"
                                    v-model="userEmail"
                                    name="login-email"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="john@example.com"
                                    autofocus
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group
                            label-for="password"
                            label="Contraseña">
                            <validation-provider
                                #default="{ errors }"
                                name="contraseña"
                                rules="required"
                            > 
                                <b-input-group
                                    class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        class="form-control-merge"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-password"
                                        placeholder="Password"
                                    />

                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- BUTTON SUBMIT -->
                        <b-button-loading 
                            text="INICIAR SESIÓN" 
                            :processing="processing"
                            :block="true"
                            @process-action="login">
                        </b-button-loading>

                    </b-form>
                </validation-observer>

            </b-card>
            <!-- /Login v2 -->

        </div>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { togglePasswordVisibility } from '@core/mixins/ui/forms';
    import { getHomeRouteForLoggedInUser } from '@/auth/utils';
    import { required, email } from '@validations';
    import useJwt from '@/auth/jwt/useJwt';

    export default {
        components: {
            BButtonLoading,
            
            ValidationProvider,
            ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                logo: require('@/assets/images/logo/logo.svg'),
                processing: false,
                userEmail: '',
                password: '',
                required,
                email
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            }
        },
        methods: {
            login() {
                this.$refs.loginForm.validate().then(success => {
                    if (success) {
                        this.processing = true;

                        useJwt.login({
                            email: this.userEmail,
                            password: this.password
                        })
                        .then(response => {

                            const { userData } = response.data;

                            useJwt.setToken(response.data.accessToken);
                            useJwt.setRefreshToken(response.data.refreshToken);

                            localStorage.setItem('userData', JSON.stringify(userData));

                            this.$ability.update(userData.ability);

                            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                                .then(() => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Bienvenido(a) ${userData.names} ${userData.surnames}`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: 'Ha iniciado sesión correctamente como Administrador. ¡Ahora puedes empezar a explorar la plataforma!',
                                        }
                                    })
                                });

                        })
                        .catch(error => this.$refs.loginForm.setErrors(error.response.data.error))
                        .finally( () => this.processing = false);
                    }
                });
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>